import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Grundsteuer = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Unterschied zwischen Grundsteuer und Grunderwerbssteuer" showCalc={false} />
            <Article>
                <p>
                    Bei der Planung einer{" "}
                    <Link to="/artikel/wohnungsfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Wohnungs-
                    </Link>{" "}
                    oder{" "}
                    <Link to="/artikel/hausfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Hausfinanzierung
                    </Link>{" "}
                    kann es vorkommen, dass du es mit komplizierten Begriffen und Phrasen zu tun bekommst, die dir aus
                    der Alltagssprache nicht bekannt sind. Es gibt aber auch Vokabel, die oft paarweise auftreten und
                    aufgrund ihrer Ähnlichkeit Verwirrung stiften. Dazu gehören Grunderwerb- und Grundsteuer. Doch kein
                    Grund zur Sorge! Im folgenden Artikel erläutern wir den Unterschied.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist die Grundsteuer?</h2>
                <p>
                    Die Grundsteuer wird in Österreich dazu eingesetzt, um Grundbesitz im Inland zu besteuern.
                    Eingehoben wird sie von der Gemeinde, in der sich das jeweilige Grundstück befindet. Diese darf über
                    die Einnahmen aus der Grundsteuer zudem verfügen. Bei der Grundsteuer werden zwei Kategorien
                    unterschieden:
                </p>
                <ul>
                    <li>Die Grundsteuer A bezieht sich auf land- und forstwirtschaftliches Vermögen,</li>
                    <li>die Grundsteuer B auf Grundvermögen</li>
                </ul>
                <hr />

                <h2>Wie wird die Höhe der Grundsteuer bestimmt?</h2>
                <p>
                    Als Basis für die Berechnung der Grundsteuer wird der durch das Finanzamt bereitgestellte
                    Grundsteuermessbetrag herangezogen. Ermittelt wird dieser Betrag auf Basis des Einheitswerts des
                    betreffenden Grundstückes. Das Finanzausgleichsgesetz berechtigt die Gemeinden zudem, den
                    Grundsteuermessbetrag um maximal 500 % zu erhöhen. Daraus ergibt sich folgende Formel zur Berechnung
                    der Grundsteuer:
                </p>
                <ul>
                    <li>
                        <strong>Grundsteuermessbetrag x Hebesatz (max. 500 Prozent) = Grundsteuer</strong>
                    </li>
                </ul>
                <p>
                    Ergibt sich daraus ein Betrag von über 75 €, dann musst du die Grundsteuer in vier Teilbeträgen
                    bezahlen, die am 15. Februar, 15. Mai, 15. August und am 15. November zu entrichten sind.
                </p>
                <hr />

                <h2>Was ist die Grunderwerbsteuer?</h2>
                <p>
                    Die Grundsteuer bezieht sich (wenig überraschend) auf den Erwerb von Grundstücken innerhalb
                    Österreichs - und zwar unabhängig davon, ob die neue Eigentümer:in für die Übernahme etwas bezahlen
                    musste. Steuerpflichtig bist du ab dem Zeitpunkt, an dem das Geschäft durch das Unterzeichnen des
                    Kaufvertrages rechtskräftig ist. Die Eintragung ins{" "}
                    <Link to="/artikel/grundbuch-kosten/" target="_blank" rel="noreferrer noopener">
                        Grundbuch
                    </Link>{" "}
                    ist für die Grundsteuer ebenfalls nebensächlich.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie wird die Höhe der Grunderwerbsteuer festgelegt?</h2>
                <p>
                    Bemessungsgrundlage der Grunderwerbsteuer ist üblicherweise der Wert der Gegenleistung, die die
                    Käufer:in für den Erwerb des jeweiligen Grundstücks aufbringt. Dazu zählen nicht nur sämtliche
                    Zahlungen an die Verkäufer:in, sondern auch die Entrichtung von Entgelten an Dritte, die auf diese
                    Weise beispielsweise dazu bewegt wurden, von ihrem Kaufrecht zurückzutreten. Wenn du ein Grundstück
                    durch eine Schenkung erhältst oder es von einer verstorbenen Person an dich vererbt wird, so wird
                    sein Wert als Bemessungsgrundlage herangezogen. Eine ungefähre Vorstellung von diesem Wert erhält
                    man durch den{" "}
                    <a
                        href="https://www.bmf.gv.at/themen/steuern/immobilien-grundstuecke/grunderwerbsteuer/bemessungsgrundlage.html"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Grundstückswertmesser des Bundesministeriums für Finanzen
                    </a>
                    , der ähnlich funktioniert wie unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"grundsteuer"}></BreadcrumbList>
            <ArticleStructuredData
                page={"grundsteuer"}
                heading={"Unterschied zwischen Grundsteuer und Grunderwerbssteuer"}
            />
        </Layout>
    );
};

export default Grundsteuer;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.grundsteuer", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
